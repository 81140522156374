import React from 'react'

import Layout from '../../components/theme/layout'
import Seo from '../../components/seo'

import './legal.scss'

export default function DataProcessing() {
    return (
        <Layout>
            <Seo title='Data processing policy' />
            <div className='legal-container'>
                <h1>Personal Data Processing Addendum</h1>

                <p>This personal data processor addendum (this <strong>“Addendum”</strong>) is entered into between the customer that enters into an agreement regarding the use of Qiwio services as supplied by the Supplier
                (the <strong>“Customer”</strong>) and Qiwio AB, reg. no. 559206-6376, (the <strong>“Supplier”</strong>). </p>
                <p>The above parties are hereinafter each referred to as a <strong>“Party”</strong> and jointly as the <strong>“Parties”</strong>.</p>

                <ol>
                    <li><strong>Introduction</strong></li>
                    <ol>
                        <li>
                            <p>1. The Parties will in conjunction with this Addendum, enter into an agreement regarding the Supplier’s provision of the Qiwio services to the Customer (or has previously entered into such agreement),
                            hereinafter, in the Addendum, referred to as the <strong>“Main Agreement”</strong>.</p>
                        </li>
                        <li>
                            <p>2. Pursuant to the undertakings which follow from the Main Agreement, the Supplier may process personal data as well as other information on behalf of the Customer. As a consequence thereof, the Parties
                            are entering into this Addendum to govern the conditions for the Supplier’s Processing of, and access to, Personal Data belonging to the Customer. The Addendum shall apply to all agreements executed
                            between the Parties in which the Supplier is the Processor on behalf of the Customer, and the Addendum shall remain in force for as long as the Supplier Processes Personal Data on the Customer’s behalf.</p>
                        </li>
                    </ol>

                    <li><strong>Definitions</strong></li>
                    <p>Unless the circumstances clearly indicate otherwise, definitions or terms used in this document shall be defined as set forth below and any term which is used in the General Data Protection Regulation and which
                    is not stated below shall be defined as follows from Article 4 of the General Data Protection Regulation.</p>

                    <table>
                        <tbody>
                            <tr>
                                <td>"Other Regulation"</td>
                                <td>means national laws which, from time to time, apply to Processing of Personal Data (excluding the General Data Protection Regulation);</td>
                            </tr>
                            <tr>
                                <td>"Processing"</td>
                                <td>means an operation or set of operations which is performed on Personal Data or on sets of Personal Data, whether or not by automated means, such as collection, recording, organisation, structuring,
                                storage, adaptation or alteration, retrieval, consultation, use, disclosure by transmission, dissemination or otherwise making available, alignment or combination, restriction, erasure, or destruction;</td>
                            </tr>
                            <tr>
                                <td>"General Data Protection Regulation"</td>
                                <td>means Regulation (EU) 2016/679 of the European Parliament and of the Council of 27 April 2016 on the protection of natural persons with regard to the Processing of Personal Data and on the free movement
                                of such data (General Data Protection Regulation);</td>
                            </tr>
                            <tr>
                                <td>"Instruction"</td>
                                <td>means the instructions which the Customer gives to the Supplier within the scope of this Addendum;</td>
                            </tr>
                            <tr>
                                <td>"Personal Data"</td>
                                <td>means any information relating to an identified or identifiable natural person, whereupon an identifiable natural person is a person who can be identified, directly or indirectly, in particular by reference
                                to an identifier such as a name, an identification number, location data or online identifiers, or one or more factors specific to the physical, physiological, genetic, mental, economic, cultural, or social
                                identity of that natural person;</td>
                            </tr>
                            <tr>
                                <td>"Controller"</td>
                                <td>means a natural or legal person, public authority, institution, or other body which, alone or jointly with others, determines the purposes and means of the Processing of Personal Data; where the purposes
                                and means of such Processing are determined by Union law or Member State law, the Controller or the specific criteria for its nomination may be provided for by Union law or Member State law;</td>
                            </tr>
                            <tr>
                                <td>"Processor"</td>
                                <td>means a natural or legal person, public authority, institution, or other body which processes Personal Data on behalf of the Controller;</td>
                            </tr>
                            <tr>
                                <td>"Personal Data Breach"</td>
                                <td>means a breach of security leading to the accidental or unlawful destruction, loss, alteration, unauthorised disclosure of, or access to, Personal Data transmitted, stored, or otherwise Processed;</td>
                            </tr>
                            <tr>
                                <td>"Data Subject"</td>
                                <td>means the living natural person who is alive and whose Personal Data is Processed; and</td>
                            </tr>
                            <tr>
                                <td>"Data Protection Laws"</td>
                                <td>means the General Data Protection Regulation and Other Regulation collectively.</td>
                            </tr>
                        </tbody>
                    </table>

                    <li><strong>Documents</strong></li>
                    <p>The Addendum comprises this document and the appended Instruction. In the event of any contradictions between this document and the Instruction, this document shall take precedence, unless otherwise specifically
                    stipulated or clearly indicated by the circumstances.</p>

                    <li><strong>Generally regarding the Processing of Personal Data</strong></li>
                    <ol>
                        <li>
                            <p>1. This Addendum applies where, within the scope of the Main Agreement, the Customer acts in the capacity of a Controller of the Personal Data which is Processed. Under such circumstances, the Supplier
                            is regarded as the Processor on behalf of the Customer.</p>
                        </li>
                        <li>
                            <p>2. The Supplier has provided sufficient guarantees that it shall take suitable technical and organisational measures to ensure that the Processing of Personal Data meets the requirements of the General
                            Data Protection Regulation and any Other Regulation, and ensures protection of the rights of the Data Subject.</p>
                        </li>
                        <li>
                            <p>3. Taking into consideration the nature of the Processing, the Supplier shall assist the Customer by taking suitable technical and organisational measures, to the extent possible, to enable the Customer
                            to perform its obligation to respond to requests regarding the exercise of the Data Subject’s rights in accordance with Chapter III of the General Data Protection Regulation.</p>
                        </li>
                        <li>
                            <p>4. If the Supplier believes that the Instruction or other instruction or notification from the Customer would conflict with the General Data Protection Regulation or any Other Regulation, the Supplier
                            shall be entitled to notify the Customer and defer the Processing in question.</p>
                        </li>
                    </ol>

                    <li><strong>The Supplier’s personnel, etc.</strong></li>
                    <ol>
                        <li>
                            <p>1. The Supplier, its employees, and other persons who perform work under the Supplier’s supervision and who gain access to Personal Data belonging to the Customer may only process such Personal Data on
                            the Customer’s instruction, unless such person is obligated to do so pursuant to Union law or Swedish national law.</p>
                        </li>
                        <li>
                            <p>2. The Supplier shall ensure that its employees and all other persons for whom the Supplier is liable and who are authorised to process Personal Data covered by this Addendum have undertaken to maintain
                            confidentiality (unless such person is subject to an appropriate statutory confidentiality obligation).</p>
                        </li>
                    </ol>

                    <li><strong>Security</strong></li>
                    <ol>
                        <li>
                            <p>1. The Supplier shall take all safeguards required under Article 32 of the General Data Protection Regulation.</p>
                        </li>
                        <li>
                            <p>2. Taking into consideration the type of Processing and the information which the Supplier has, the Supplier shall assist the Customer in ensuring that the obligations regarding security can be satisfied
                            in a manner which follows from Article 32 of the General Data Protection Regulation.</p>
                        </li>
                        <li>
                            <p>3. In conjunction with the assessment of an appropriate security level, particular consideration shall be given to the risks which follow from the Processing, particularly resulting from unintentional or
                            unlawful destruction, loss, or modification, from unauthorised disclosure, or from unauthorised access to the Personal Data which is transferred, stored, or otherwise processed.</p>
                        </li>
                    </ol>

                    <li><strong>Personal Data Breach</strong></li>
                    <p>Taking into consideration the type of Processing and the information available to the Supplier, the Supplier shall assist the Customer in ensuring that the obligations arising due to any Personal Data Breach can
                    be fulfilled in a manner as required in Articles 33-34 of the General Data Protection Regulation.</p>

                    <li><strong>Impact assessment and prior consultation</strong></li>
                    <p>Taking into consideration the nature of the Processing and the information which is available to the Supplier, the Supplier shall assist the Customer in fulfilling its obligations, if any, to conduct an impact
                    assessment and/or prior consultation with a supervisory authority pursuant to Articles 35 and 36 of the General Data Protection Regulation.</p>

                    <li><strong>The Instruction</strong></li>
                    <ol>
                        <li>
                            <p>1. The Instruction shall, inter alia, state the subject of the Processing, the duration of the Processing, the nature and purpose of the Processing, the type of Personal Data, and categories of Data Subjects.</p>
                        </li>
                        <li>
                            <p>2. The Supplier may only process the Personal Data which is covered under this Addendum on the documented Instruction (including in respect of transfers of Personal Data to a third country or an international
                                organisation, provided such Processing is not required pursuant to EU law or the national law of a Member State to which the Supplier is subject and, in such case, the Supplier shall inform the Customer of the
                                legal requirement before the data is Processed, unless such information is prohibited with reference to an important public interest under relevant national law).</p>
                        </li>
                        <li>
                            <p>3. The Customer shall be entitled to update the Instruction from time to time by giving the Supplier written notice. The Supplier shall be entitled to compensation for additional costs incurred if the Customer
                            modifies the Instruction.</p>
                        </li>
                    </ol>

                    <li><strong>Subprocessors</strong></li>
                    <ol>
                        <li>
                            <p>1. The Supplier shall not be entitled to retain subprocessors to perform the work under the Addendum without first obtaining the Customer’s written approval. Such written approval may be specific (relate to a
                                specific subprocessor) or general (and shall not be unreasonably denied).</p>
                        </li>
                        <li>
                            <p>2. Where the Customer has granted a general prior consent in writing, the Supplier shall inform the Customer of any plans to retain a new subprocessor or to replace an existing subprocessor, in order to allow
                            the Customer to make objections to any such change (however, any objection must be based on an objectively acceptable reason).</p>
                        </li>
                        <li>
                            <p>3. Where the Customer has granted written approval, and irrespective of whether such approval is specific or general, the Supplier shall ensure that any such subprocessor enters into a written personal data
                            processor agreement before the subprocessor begins work related to the Customer. Any such personal data processor agreement must contain the undertakings and obligations which follow from the Addendum. In any
                            such a personal data processor agreement, the subprocessor shall provide sufficient warranties in respect of taking suitable technical and organisational measures so that the Processing meets the requirements
                            of the General Data Protection Regulation.</p>
                        </li>
                        <li>
                            <p>4. In the event the subprocessor fails to fill its obligations, the Supplier shall be liable to the Customer for the performance of the subprocessor’s obligations.</p>
                        </li>
                        <li>
                            <p>5. The Supplier is aware that it must comply with the provisions regarding retention of subprocessors.</p>
                        </li>
                    </ol>

                    <li><strong>Transfer to a third country</strong></li>
                    <p>The Supplier may move, store, transfer, or otherwise process Personal Data belonging to the Customer outside of the EU/EEA, provided such transfer meets the requirements and undertakings which follow from the General
                    Data Protection Regulation.</p>

                    <li><strong>Right to transparency</strong></li>
                    <p>The Supplier shall grant the Customer access to all information which is required and necessary to enable the Customer to verify compliance with the obligations which follow from Article 28 of the General Data
                    Protection Regulation and to enable and assist in audits, including inspections, which are conducted by the Customer or by an examiner authorised by the Customer. The Supplier shall, at all times, be entitled to reasonable
                    notice in the event the Customer wishes to exercise its right to conduct an audit or inspection and the Customer shall compensate the Supplier for its costs incurred in connection with any such audit or inspection.</p>

                    <li><strong>Compensation</strong></li>
                    <p>The Supplier shall not receive compensation for measures which it takes in respect of Processing of Personal Data in accordance with the Addendum or as a consequence of the Addendum otherwise, provided that such actions
                    are taken as part of the Supplier’s ordinary activities and / or own routines for the processing of Personal Data. If such measures instead result in additional work for the Supplier, the Supplier shall be entitled to
                    reasonable compensation for these.</p>

                    <li><strong>Liability</strong></li>
                    <p>In the event the Parties have reached an agreement regarding limitation of liability in another agreement (such as the Main Agreement), such limitation of liability shall also apply to this Addendum. In the event the
                    Parties have not reached an agreement regarding such a limitation of liability, a Party’s liability under this Addendum or as a result of the Processing which is covered under the Addendum shall be limited to one hundred
                    thousand kronor (SEK 100,000). Notwithstanding anything to the contrary contained herein, the following shall apply. If the Supplier’s wrongful processing of Personal Data is a result of the Customer’s wrongful acts or
                    instructions, the Customer shall compensate the Supplier for any eventual administrative fees or damages which the Supplier shall pay as a consequence of such acts/instructions (whereupon any limitation of liability provision,
                    for the avoidance of doubt, shall not apply).</p>

                    <li><strong>Termination of the ADDENDUM</strong></li>
                    <ol>
                        <li>
                            <p>1. When the Supplier discontinues Processing Personal Data on behalf of the Customer, the Supplier shall return all Personal Data to the Customer in the manner instructed by the Customer or, upon the Customer’s
                            written notice, destroy and erase all Personal Data which is associated with the Addendum.</p>
                        </li>
                        <li>
                            <p>2. Following termination of the Addendum, the Supplier shall not be entitled to save any Personal Data belonging to the Customer and, as soon as the Supplier has complied with the provisions of subsection 16.1 above,
                            the Supplier’s right to process or otherwise use Personal Data belonging to the Customer shall cease (provided storage of Personal Data is not required pursuant to national law or Union law, or the Supplier has legal
                                grounds to process relevant Personal Data).</p>
                        </li>
                    </ol>

                    <li><strong>Assignment of the ADDENDUM</strong></li>
                    <p>The Supplier but not the Customer shall be entitled to assign its rights and/or obligations under the Addendum, in whole or in part, without the prior written consent of the other Party.</p>

                    <li><strong>Governing law and Jurisdiction</strong></li>
                    <ol>
                        <li>
                            <p>1. This Addendum shall be governed by the substantive law of Sweden (not including its conflict of law provisions).</p>
                        </li>
                        <li>
                            <p>2. Any dispute, controversy or claim arising out of or in connection with this Addendum, or the breach, termination or invalidity thereof, shall be finally settled in accordance with the provisions of the Main
                            Agreement (or, as applicable, any other agreement referred to in Clause 1.3) regarding disputes.</p>
                        </li>
                        <li>
                            <p>3. The Parties undertake and agree that all arbitral proceedings conducted with reference to this arbitration clause will be kept strictly confidential. This confidentiality undertaking shall cover all information
                            disclosed in the course of such arbitral proceedings, as well as any decision or award that is made or declared during the proceedings. Information covered by this confidentiality undertaking may not be disclosed
                            to a third party without the prior consent by the other Party. Exceptions to the foregoing shall only apply to the extent that disclosure may be required of a Party due to mandatory law, an order of a competent
                            court or public authority, or to protect, fulfil or pursue a legitimate legal right or obligation or to enforce or challenge an award.</p>
                        </li>
                    </ol>
                </ol>



                <h2>Instruction</h2>

                <p>This is the Instruction. Definitions used in this Instruction shall have the same meaning as in the Addendum unless the circumstances clearly indicate otherwise.</p>

                <ol>
                    <li><strong>Processing of personal data</strong></li>
                    <ol>
                        <li><strong>1.1 Categories of personal data</strong></li>
                        <p>Supplier may Process the following categories of Personal Data:</p>

                        <ol>
                            <li>
                                <p>1. Information about who specifically viewed certain content and how any times a particular content was viewed by a particular viewer.</p>
                            </li>
                            <li>
                                <p>2. Standard contract information such as name, title, email address, physical address, phone number, etc.</p>
                            </li>
                            <li>
                                <p>3. Information about an individual’s computer or mobile device or technology usage, including (for example) IP address, MAC address, unique device identifiers, unique identifiers set in cookies, and any information
                                passively captured about a person’s online activities, browsing, application or hotspot usage or device location.</p>
                            </li>
                            <li>
                                <p>4. Information about how viewers engage with data, for example, which links they click, questions they answer.</p>
                            </li>
                        </ol>

                        <li><strong>1.2 Special categories of Personal Data</strong></li>
                        <p>Most likely, no special categories of Personal Data will be processed by the Supplier. However, if the Supplier shall be provided with sensitive data, the Customer undertakes to take the securities measures needed for such
                        Processing (as set out in Data Protection Laws) on beforehand. Before such measures are taken, the Customer undertakes to not let the Supplier Process any sensitive data.</p>

                        <li><strong>1.3 Categories of Processing</strong></li>
                        <p>The following categories of Processing may take place:</p>

                        <ol>
                            <li>1. Collection</li>
                            <li>2. Organisation/structuring</li>
                            <li>3. Storage</li>
                            <li>4. Erasure</li>
                            <li>5. Adaption/alternation</li>
                            <li>6. Compilation of data and reporting to relevant authorities</li>
                            <li>7. Make backup copies for security reasons</li>
                            <li>8. Data needed to fix bugs, ensuring operations and safety</li>
                            <li>9. Otherwise in such ways which are necessary for the fulfilment of obligations according to the Main Agreement and applicable laws</li>
                        </ol>

                        <li><strong>1.4 Categories of Data Subjects</strong></li>
                        <p>The following categories of Data Subjects may be included: </p>

                        <ol>
                            <li>1. The employees and consultants of the Customer</li>
                            <li>2. The Customers current and potential customers, suppliers and business partners, or, should such customers, suppliers and business partners be legal entities, their representatives (i.e. their employees,
                                consultants, directors etc.)</li>
                        </ol>

                         <li><strong>1.5 Purpose of each Processing activity</strong></li>
                        <p>All Processing of Personal Data mainly take place for the purpose of the Supplier being able to provide its services in accordance with the Main Agreement (and to provide related support and other related services).
                        The purpose of the Processing is also that the Supplier shall be able to fulfil its obligations under agreements and applicable laws (including Data Protection Laws), and to be able to defend itself in the event of legal claims.</p>
                    </ol>

                    <li><strong>Security Measures</strong></li>

                    <ol>
                        <li><strong>2.1 Technical and organisational security measures</strong></li>
                        <p>The Supplier take technical and organisational security measures according to the Personal Data Processor Agreement and the General Data Protection Regulation as well as Other Regulation. Besides what it set out therein, and more
                        specifically, the following technical and organisational security measures shall be taken:</p>

                        <ol>
                            <li>1. All personal data is stored and kept within the EU.</li>
                            <li>2. We perform back ups of all personal data on a consistent basis.</li>
                            <li>3. We use advanced data security measures provided by our cloud infrastructure suppliers to provide best in class technical security of data.</li>
                        </ol>

                        <li><strong>2.2 Storage minimisation</strong></li>
                        <p>The Customer may at any time, in accordance with its routines for culling, erase or require the erasure of Personal Data and other information which has been disclosed to the Supplier in connection with the Supplier’s provision
                        of its services according to the Main Agreement. If this is done, this can result in the Supplier not being able to provide the Service in accordance with what has been agreed. The rights stated above shall not apply to such data i)
                        which the Supplier is required to store for a longer period according to, and to the extent required, by law, ii) which the Supplier needs to Process to be able to defend itself in the event of legal claims, and/or iii) which the
                        Supplier is entitled to retain according to the Main Agreement (such as in order to improve and/or market its services).</p>

                        <p>The Supplier may continuously make backup copies of information provided through the Service. Backup copies may not be stored for a longer period than necessary and never for a longer period than [two] years from the day when
                        the Supplier received the information.</p>
                    </ol>
                </ol>

                <p><strong>Last updated: May 26, 2021</strong></p>
            </div>
        </Layout>
    )
}